import React, { type ForwardedRef, forwardRef, type ReactNode } from 'react';
import { type RulesExtend, useFelaEnhanced } from '@ackee/fela';

import type { Column } from 'modules/app-development-page/content';
import type { TitleType } from 'modules/ui/Title/Title.sizes';

import * as UI from '../ui';
import * as felaRules from './Hero.rules';

import headerPlaceholder from '../../../static/images/hp/header_placeholder_optimized.jpg';
import Columns from './Columns';
import ClientsSection from '../clients/ClientsSection';

const { Title, TitleTypes, DynamicContainer, Text, TextTypes, ContainerType } = UI;

interface HeroProps {
    showImage?: boolean;
    showLogos?: boolean;
    sectionTitle?: ReactNode;
    title?: ReactNode;
    description?: ReactNode;
    background?: string;
    columns?: Column[];
    showColumns?: boolean;
    // TODO: use type from Title component after it is migrated to TS
    titleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    titleType?: TitleType;
    isCareer?: boolean;
    address?: ReactNode;
    customAnimationObject?: ReactNode;
    customContent?: ReactNode;
    isAppDevelopmentWhy?: boolean;
    noPaddingBottom?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

export const Hero = forwardRef(
    (
        {
            showImage,
            sectionTitle,
            title,
            description,
            showLogos,
            background,
            address,
            showColumns,
            columns,
            customAnimationObject,
            titleAs = TitleTypes.H2,
            titleType = TitleTypes.H1_ITALIC_UPPERCASE,
            customContent,
            isAppDevelopmentWhy,
            isCareer,
            noPaddingBottom,
            extend,
        }: HeroProps,
        ref: ForwardedRef<HTMLElement>,
    ) => {
        const { styles, rules } = useFelaEnhanced(felaRules, {
            showImage,
            showLogos,
            showColumns,
            isAppDevelopmentWhy,
            background,
            noPaddingBottom,
            isCareer,
            extend,
        });

        return (
            <>
                <DynamicContainer<{
                    showLogos: HeroProps['showLogos'];
                    background: HeroProps['background'];
                    isCareer: HeroProps['isCareer'];
                }>
                    columns={12}
                    as={ContainerType.SECTION}
                    type={ContainerType.FLEX}
                    showLogos={showLogos}
                    extend={{ container: felaRules.heroWrapper }}
                    ref={ref}
                    background={background}
                    isCareer={isCareer}
                >
                    <DynamicContainer<{
                        showLogos: HeroProps['showLogos'];
                        isAppDevelopmentWhy: HeroProps['isAppDevelopmentWhy'];
                        showColumns: HeroProps['showColumns'];
                    }>
                        isFluid
                        columns={12}
                        showColumns={showColumns}
                        type={ContainerType.GRID}
                        extend={{ container: felaRules.container }}
                        isAppDevelopmentWhy={isAppDevelopmentWhy}
                        showLogos={showLogos}
                    >
                        <DynamicContainer
                            columns={sectionTitle ? 9 : 10}
                            extend={{ container: felaRules.heroContentContainer }}
                        >
                            {/* @ts-expect-error */}
                            {sectionTitle && <Text type={TextTypes.SECTION}>{sectionTitle}</Text>}
                            {title && (
                                <>
                                    {/* @ts-expect-error */}
                                    <Title
                                        type={titleType}
                                        as={titleAs}
                                        showColumns={showColumns}
                                        extend={{ title: rules.extendMainTitle }}
                                        isCareer={isCareer}
                                    >
                                        {title}
                                    </Title>
                                </>
                            )}
                            {description && (
                                <div className={styles.descriptionContainer}>
                                    {/* @ts-expect-error */}
                                    <Text as="p" extend={{ text: rules.description }} type={TextTypes.LARGE}>
                                        {description}
                                    </Text>
                                </div>
                            )}
                        </DynamicContainer>
                        {showImage && (
                            <>
                                <DynamicContainer
                                    columns={4}
                                    justify="center"
                                    extend={{ container: felaRules.heroImageContainer }}
                                >
                                    <img src={headerPlaceholder} className={styles.heroImage} alt="Test placeholder" />
                                </DynamicContainer>
                            </>
                        )}
                        {customAnimationObject && customAnimationObject}
                        {showColumns && <Columns content={columns} />}
                    </DynamicContainer>
                    {customContent && customContent}

                    {showLogos && (
                        <>
                            {/* @ts-expect-error */}
                            <ClientsSection />
                        </>
                    )}
                    {address && address}
                </DynamicContainer>
            </>
        );
    },
);
