import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../ui';

const { Video } = UI;

const HeroVideo = ({ styles, src, replayOnScroll }) => {
    const videoRef = React.useRef();

    React.useEffect(() => {
        if (!videoRef?.current) {
            return;
        }

        const isPlaying =
            videoRef.current.currentTime > 0 &&
            !videoRef.current.paused &&
            !videoRef.current.ended &&
            videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

        const handler = play => entries => {
            const { isIntersecting } = entries[0];

            if (isIntersecting && play) {
                // we want to prevent replaying the video from scratch if it is in user viewport
                if (!videoRef.current.ended) {
                    videoRef.current.play();
                }
            } else if (!isIntersecting && !play && replayOnScroll && isPlaying) {
                videoRef.current.load(); // load means reset video
                videoRef.current.pause(); // after reset, we need to pause it
            } else if (!isIntersecting && !play && !replayOnScroll && isPlaying) {
                videoRef.current.pause(); // pause without reset if we dont want reset the video out of viewport
            }
        };

        const observers = [
            new IntersectionObserver(handler(true), {
                threshold: 0.9,
            }),
            new IntersectionObserver(handler(false)),
        ];
        observers.forEach(observer => observer.observe(videoRef.current));

        // eslint-disable-next-line consistent-return
        return () => observers.forEach(observer => observer.disconnect());
    }, [videoRef, replayOnScroll]);

    return (
        <>
            <div className={styles.container}>
                <Video customRef={videoRef} hideControls autoPlay src={src} />
            </div>
        </>
    );
};

HeroVideo.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    src: PropTypes.string.isRequired,
    replayOnScroll: PropTypes.bool,
};

HeroVideo.defaultProps = {
    replayOnScroll: true,
};

export default HeroVideo;
