import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';

import BlogItem from './BlogItem';

const { DynamicContainer, Text, TextTypes } = UI;

const BlogSection = ({ styles, rules, content }) => (
    <DynamicContainer isFluid extend={{ container: rules.container }}>
        <Text extend={{ text: rules.title }} type={TextTypes.SECTION}>
            <FormattedMessage id={content.title} />
        </Text>
        <div className={styles.blogItemsContainer}>
            {content.blogItems.map(item => (
                <BlogItem key={item.title} type={item.type} content={item} />
            ))}
        </div>
    </DynamicContainer>
);

BlogSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    content: PropTypes.shape({
        title: PropTypes.string,
        blogItems: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
};

export default BlogSection;
