import type { TRuleWithTheme } from '@ackee/fela';

export const heroWrapper: TRuleWithTheme<{ background?: string; showLogos?: boolean; isCareer?: boolean }> = ({
    background,
    showLogos,
    isCareer,
}) => {
    const backgroundStyle = background
        ? {
              backgroundImage: `url(${background})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '100% 100px',
          }
        : null;

    return {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...backgroundStyle,
        overflow: 'visible',
        paddingTop: '6rem',
        ...(isCareer && { marginBottom: '12rem' }),

        desktop1200: {
            minHeight: showLogos && '100vh',
            paddingTop: 0,
        },

        desktopFullHD: {
            paddingTop: 0,
        },
    };
};

interface ContainerProps {
    showColumns?: boolean;
    noPaddingBottom?: boolean;
    isAppDevelopmentWhy?: boolean;
}

export const container: TRuleWithTheme<ContainerProps> = ({ showColumns, noPaddingBottom, isAppDevelopmentWhy }) => ({
    alignItems: 'flex-end',
    overflow: 'visible',
    paddingBottom: noPaddingBottom ? 0 : '2rem',
    ...(isAppDevelopmentWhy && { paddingTop: 0 }),

    tabletLarge: {
        paddingTop: isAppDevelopmentWhy ? 0 : '11.25rem',
        paddingBottom: showColumns ? '8.4375rem' : 0,
    },

    desktopFullHD: {
        paddingTop: !isAppDevelopmentWhy ? '25rem' : 0,
    },
});

export const heroContentContainer: TRuleWithTheme<{ isCareer?: boolean }> = ({ isCareer }) => ({
    position: 'relative',
    zIndex: 2,
    ...(isCareer && { overflow: 'visible' }),
});

export const heroImageContainer: TRuleWithTheme = () => ({
    overflow: 'visible',
    position: 'absolute',
    zIndex: 1,
    top: '8.5rem',
    left: '27.73333vw',
    width: '100vw',

    tablet: {
        position: 'initial',
        width: 'initial',
        top: 'initial',
        left: 'initial',
    },
});

export const heroAnimationContainer: TRuleWithTheme = () => ({
    overflow: 'visible',
    position: 'relative',
    zIndex: 1,
});

export const heroImage: TRuleWithTheme = () => ({
    width: '100%',
    maxWidth: 390,
    marginBottom: '-5.6rem',
});

export const heroPhone: TRuleWithTheme = () => ({
    width: '100%',
    marginBottom: '-50%',
    opacity: 0,
});

export const extendMainTitle: TRuleWithTheme<{ showColumns?: boolean; isCareer?: boolean }> = ({
    showColumns,
    isCareer,
}) => ({
    ...(isCareer && { fontSize: '1.5rem' }),

    marginBlockStart: 0,

    marginBottom: showColumns ? '9.5rem' : '2.5rem',

    tabletLarge: {
        maxWidth: '90%',
    },

    desktopLarge: {
        maxWidth: '60rem',
    },
});

export const descriptionContainer: TRuleWithTheme<{ isCareer?: boolean }> = ({ isCareer }) => ({
    maxWidth: isCareer ? '45.375rem' : '37.5rem',
    fontSize: '1.125rem',
    overflow: 'visible',
    position: 'relative',
    marginBottom: '5.625rem',
});

export const description = () => ({});
