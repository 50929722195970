export const centeredContainer = ({ theme: { colors } }) => ({
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 2,
    paddingBottom: '2rem',
    paddingTop: '2rem',
    backgroundColor: colors.white,
    position: 'relative',
    overflow: 'visible',
});

export const title = ({ theme: { colors } }) => ({
    textTransform: 'uppercase',
    color: colors.black,
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '1.33rem',

    tablet: {
        marginLeft: 'initial',
        marginTop: '2rem',
    },

    tabletLarge: {
        marginTop: '2rem',
    },

    desktopFullHD: {
        marginBottom: '2rem',
    },
});
