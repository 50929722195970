import { ItemType } from './components/BlogSection/BlogItem/ItemType';

const ids = {
    inquiry: 'inquiry',
    hr: 'hr',
};

const getBlogItems = (count, id) => ({
    title: `thankYouPage.${id}.blogSection.title`,
    blogItems: Array.from(Array(count)).map((_, index) => ({
        image: `thank-you-page/blogs/blog_${id}_${index + 1}.png`,
        title: `thankYouPage.${id}.blogItem.title.${index + 1}`,
        link: `thankYouPage.${id}.blogItem.link.${index + 1}`,
        type: id === ids.inquiry ? ItemType.HUGE : ItemType.SMALL,
    })),
});

const contentArray = Object.values(ids).map(id => ({
    heroImage: `thank-you-page/hero-images/hero-${id}.jpg`,
    title: `thankYouPage.${id}.title`,
    description: `thankYouPage.${id}.description`,
    blogs: getBlogItems(id === ids.inquiry ? 2 : 3, id),
    endSection: `thankYouPage.${id}.endSection`,
}));

export const content = {
    [ids.inquiry]: contentArray[0],
    [ids.hr]: contentArray[1],
};
