export const extendTitle = {
    title: () => ({
        marginTop: '1.25rem',
        marginBottom: '1.5rem',
        fontSize: '2rem',
        lineHeight: '2.625rem',

        tablet: {
            display: 'block',
        },
    }),
};

export const extendContainer = {
    container: () => ({
        paddingRight: '2.7rem',
        paddingTop: '1rem',
        paddingBottom: '2rem',

        tabletLarge: {
            gridColumnGap: '2.5rem',
            gridTemplateColumns: 'repeat(auto-fill, minmax(5.5%, 1fr))',
            paddingTop: '2rem',
            paddingBottom: 0,
        },
    }),
};

export const extendDividerContainer = {
    container: () => ({
        height: '13rem',

        tabletLarge: {
            height: '18rem',
        },
    }),
};

export const textContainer = {
    paddingLeft: '2.3125em',
};
