export const title = () => ({
    tablet: {
        fontSize: '3rem',
        marginTop: '10%',
    },

    desktopLarge: {
        maxWidth: '50rem',
    },
});

export const descriptionContainer = () => ({
    maxWidth: '42rem',
});

export const container = () => ({
    paddingTop: 0,

    tabletLarge: {
        paddingTop: 0,
    },

    maxWidth: '41.25rem',
});

export const names = () => ({
    display: 'block',
    marginTop: '1.5rem',
});

export const link = ({ theme: { colors, transition } }) => ({
    textDecoration: 'none',
    color: colors.blue,

    transition,
    transitionProperty: 'color',

    ':hover': {
        color: colors.fuchsia,
    },
});
