import { ItemType } from './ItemType';

const typeToStyleMap = {
    [ItemType.HUGE]: () => ({
        marginBottom: '2.5rem',

        tablet: {
            width: '30.303%',
            marginRight: '2.5rem',
            marginBottom: 'unset',
        },
    }),
    [ItemType.SMALL]: () => ({
        marginBottom: '2.5rem',

        tablet: {
            width: '18.409%',
            marginRight: '1.5rem',
            marginBottom: 'unset',
        },
    }),
};

export const container = ({ type, theme: { colors, transition } }) => ({
    width: '100%',
    textDecoration: 'none',
    color: colors.black,

    transition,
    transitionProperty: 'color',

    ...typeToStyleMap[type](),

    ':hover': {
        color: colors.fuchsia,
    },
});

export const title = ({ theme: { fonts } }) => ({
    ...fonts.heroNewBold,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textDecoration: 'none',
});
