import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../ui';

import SingleColumn from './SingleColumn';

const { DynamicContainer, ContainerType } = UI;

const Columns = ({ content }) => (
    <DynamicContainer columns={12} type={ContainerType.GRID}>
        {content.map((item, index) => (
            <SingleColumn
                key={item.id}
                isLast={index === content.length - 1}
                title={item.title}
                description={item.description}
            />
        ))}
    </DynamicContainer>
);

Columns.propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Columns;
