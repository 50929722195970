import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';

const { DynamicContainer, SlantedDivider, Title, Text, TextTypes } = UI;

const SingleColumn = ({ rules, styles, title, description, isLast }) => (
    <DynamicContainer isLast={isLast} extend={rules.extendContainer} columns={4}>
        <SlantedDivider extend={rules.extendDividerContainer} />
        <div className={styles.textContainer}>
            <Title extend={rules.extendTitle}>
                <FormattedMessage id={title} />
            </Title>
            <Text type={TextTypes.LARGE} as="p">
                <FormattedMessage id={description} />
            </Text>
        </div>
    </DynamicContainer>
);

SingleColumn.propTypes = {
    rules: PropTypes.shape().isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isLast: PropTypes.bool.isRequired,
};

export default SingleColumn;
