export const container = () => ({
    paddingTop: 0,

    tabletLarge: {
        paddingTop: 0,
        paddingBottom: '7.5rem',
    },
});

export const title = ({ theme: { colors } }) => ({
    color: colors.black,
});

export const blogItemsContainer = () => ({
    display: 'flex',
    flexDirection: 'column',

    tablet: {
        flexDirection: 'row',
    },
});
