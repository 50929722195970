import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import BlogImage from '../BlogImage';

const BlogItem = ({ styles, content }) => {
    const { formatMessage } = useIntl();

    const link = formatMessage({ id: content.link });

    return (
        // eslint-disable-next-line
        <a target="_blank" className={styles.container} href={link}>
            <BlogImage src={content.image} />
            <h3 className={styles.title}>
                <FormattedMessage id={content.title} />
            </h3>
        </a>
    );
};

BlogItem.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
};

export default BlogItem;
