export const container = () => ({
    position: 'absolute',
    top: '15%',
    zIndex: -2,
    width: '40vw',
    height: '40vw',
    display: 'none',
    right: '5%',

    tabletLarge: {
        display: 'block',
        width: '40vw',
        height: '40vw',
        right: '5%',
        top: '15%',
    },

    desktopFullHD: {
        width: '33vw',
        height: '33vw',
        maxWidth: '100%',
    },
});
