import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage, useIntl } from 'react-intl';

import Hero from '../../../hero-section';
import HeroImage from '../HeroImage';
import BlogSection from '../BlogSection';

const { DynamicContainer, TitleTypes, Text, TextTypes } = UI;

const ThankYouPage = ({ styles, rules, content }) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <Hero
                title={<FormattedMessage id={content.title} />}
                description={<FormattedMessage id={content.description} />}
                titleAs={TitleTypes.H1}
                titleType={TitleTypes.H1}
                extend={{
                    extendMainTitle: rules.title,
                    descriptionContainer: rules.descriptionContainer,
                }}
                customContent={<HeroImage src={content.heroImage} />}
            />
            <BlogSection content={content.blogs} />
            <DynamicContainer isFluid extend={{ container: rules.container }}>
                <Text type={TextTypes.LARGE}>
                    <FormattedMessage
                        id={content.endSection}
                        values={{
                            names: (...chunks) => <span className={styles.names}>{chunks}</span>,
                            link: (...chunks) => (
                                <a
                                    className={styles.link}
                                    href={formatMessage({ id: `thankYouPage.inquiry.endSection.link` })}
                                >
                                    {chunks}
                                </a>
                            ),
                            br: <br />,
                        }}
                    />
                </Text>
            </DynamicContainer>
        </>
    );
};

ThankYouPage.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default ThankYouPage;
